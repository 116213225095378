<template>
    <div id="users">
        <el-breadcrumb separator="/" style="padding-left:10px;padding-bottom:10px;font-size:12px;">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
            <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">
                <el-form-item label="登录名">
                    <el-input
                            @keyup.enter.native="searchUser"
                            @clear="searchUser"
                            clearable
                            v-model="queryMap.username"
                            placeholder="请输入登录名查询"
                    ></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input clearable @clear="searchUser" v-model="queryMap.nickname"
                              placeholder="请输入姓名查询"></el-input>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input clearable @clear="searchUser" v-model="queryMap.phoneNumber"
                              placeholder="请输入电话查询"></el-input>
                </el-form-item>
              <el-form-item label="用户类型">
                <el-select
                    clearable
                    v-model="queryMap.type"
                    placeholder="请选择用户类型">
                  <el-option
                      v-for="organ in userOptions"
                      :key="organ.id"
                      :label="organ.name"
                      :value="organ.id"
                  ></el-option>
                </el-select>
              </el-form-item>
                <el-form-item style="margin-left:50px;">
                    <el-button type="warning" @click="reset" icon="el-icon-refresh">重置</el-button>
                    <el-button type="primary" @click="searchUser" icon="el-icon-search">查询</el-button>
                    <el-button
                            type="success"
                            icon="el-icon-plus"
                            @click="addFunc"
                            v-hasPermission="'user:add'"
                    >添加
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 表格区域 -->
            <el-table v-loading="loading" size="small" :data="userList" border style="width: 100%;" height="600">
                <el-table-column label="No" width="50">
                    <template slot-scope="scope">
                        {{ scope.$index + (queryMap.currentPage - 1) * queryMap.pageSize + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="账户" width="110"></el-table-column>
                <el-table-column prop="nickname" label="昵称" width="180" sortable></el-table-column>
                <el-table-column prop="phoneNumber" label="电话" width="150"></el-table-column>
                <el-table-column prop="email" label="邮箱" width="150"></el-table-column>
                <el-table-column prop="sex" label="性别" width="150">
                    <
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.sex==1">男</el-tag>
                        <el-tag v-if="scope.row.sex==0">女</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="用户类型" width="150">
                    <
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.type==0">系统用户</el-tag>
                        <el-tag v-if="scope.row.type==1">一般用户</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="150"></el-table-column>
                <el-table-column label="操作" width="400">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" icon="el-icon-edit-outline" v-if="scope.row.username != 'admin' && scope.row.type==0"
                                   @click="editFunc(scope.row.id)">编辑
                        </el-button>
                      <el-button size="small" type="info" icon="el-icon-edit-outline" v-if="scope.row.username != 'admin' && scope.row.type==1"
                                 @click="info(scope.row.id)">详细
                      </el-button>
                        <el-button type="danger" size="small" icon="el-icon-delete" v-if="scope.row.username != 'admin'"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                        <el-button type="success" size="small" icon="el-icon-refresh-left"
                                   @click="resetPassword(scope.row.id)">重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination
                    style="margin-top:10px;"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="queryMap.pageNo"
                    :page-sizes="[10, 20, 30]"
                    :page-size="queryMap.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
            ></el-pagination>
            <!-- 添加对话框 -->
            <el-dialog class="class_dialog_hospital" :title="dialogTitle" @close="closeDialog"
                       :visible.sync="addDialogVisible" width="50%;">
                <!-- 表单 -->
                <el-card class="box-card">
                    <span>
                        <el-form
                                :model="addForm"
                                :label-position="labelPosition"
                                :rules="addFormRules"
                                ref="addFormRef"
                                label-width="80px">
                          <el-row>
                              <el-col :span="10">
                                  <el-form-item label="用户类型">
                                <el-select
                                        clearable
                                        v-model="addForm.type"
                                        placeholder="请选择用户类型">
                                    <el-option
                                            v-for="organ in userOptions"
                                            :key="organ.id"
                                            :label="organ.name"
                                            :value="organ.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                              </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="10">
                              <div class="grid-content bg-purple">
                                <el-form-item label="登录名" prop="username">
                                  <el-input v-model="addForm.username" maxlength="10"></el-input>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="10">
                              <div class="grid-content bg-purple">
                                <el-form-item label="真实姓名" prop="nickname">
                                  <el-input v-model="addForm.nickname" maxlength="6"></el-input>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                          <el-form-item label="性别" prop="sex">
                               <el-radio-group v-model="addForm.sex">
                                      <el-radio :label="0">女</el-radio>
                                      <el-radio :label="1">男</el-radio>
                               </el-radio-group>
                          </el-form-item>
                          <el-form-item label="密码" prop="password" v-if="dialogType == 'add'">
                            <el-input prefix-icon="el-icon-lock"
                                      :type="flag?'text':'password'"
                                      v-model="addForm.password"
                                      maxlength="12">

                                <i slot="suffix"
                                   :class="[flag?'el-icon-minus':'el-icon-view']"
                                   style="margin-top:8px;font-size:18px;"
                                   autocomplete="auto"
                                   @click="flag=!flag"/>
                            </el-input>
                          </el-form-item>
                          <el-form-item label="确认密码" prop="repassword" v-if="dialogType == 'add'">
                            <el-input v-model="addForm.repassword" type="password" maxlength="12"></el-input>
                          </el-form-item>
                          <el-form-item label="手机" prop="phoneNumber">
                            <el-input v-model="addForm.phoneNumber" maxlength="11"></el-input>
                          </el-form-item>
                          <el-form-item label="邮箱" prop="email">
                            <el-input v-model="addForm.email"></el-input>
                                     </el-form-item>
                    </el-form>
                </span>
                </el-card>
                <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button
                        type="primary"
                        @click="confirm"
                        :disabled="btnDisabled"
                >确 定</el-button>
            </span>
            </el-dialog>
          <!-- 详细对话框 -->
          <el-dialog class="class_dialog_hospital" title="详细" @close="closeDialog"
                     :visible.sync="infoDialogVisible" width="50%;">
            <!-- 表单 -->
            <el-card class="box-card">
                    <span>
                        <el-form
                            :model="addForm"
                            label-width="80px">
                          <el-row>
                              <el-col :span="10">
                                  <el-form-item label="用户类型:">
                                <el-tag v-if="addForm.type==0">系统用户</el-tag>
                                <el-tag v-if="addForm.type==1">一般用户</el-tag>
                            </el-form-item>
                              </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="10">
                              <div class="grid-content bg-purple">
                                <el-form-item label="登录名:" prop="username">
                                  <div>{{addForm.username}}</div>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                          <el-row>
                            <el-col :span="10">
                              <div class="grid-content bg-purple">
                                <el-form-item label="真实姓名:" prop="nickname">
                                   <div>{{addForm.nickname}}</div>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                          <el-form-item label="性别:" prop="sex">
                            <el-tag v-if="addForm.sex==0">女</el-tag>
                                <el-tag v-if="addForm.sex==1">男</el-tag>
                          </el-form-item>
                          <el-form-item label="手机:" prop="phoneNumber">
                            <div>{{addForm.phoneNumber}}</div>
                          </el-form-item>
                          <el-form-item label="邮箱:" prop="email">
                            <div>{{addForm.email}}</div>
                                     </el-form-item>
                    </el-form>
                </span>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="infoDialogVisible = false">取 消</el-button>
            </span>
          </el-dialog>
        </el-card>
    </div>
</template>
<script>
    import {regionData} from "element-china-area-data";
    import axios from "axios";

    export default {
        data() {
            const checkPhone = (rule, value, callback) => {
                const phoneReg = /^\d{11}$/;
                if (!value) {
                    return callback(new Error("电话号码不能为空"));
                }
                setTimeout(() => {
                    if (!Number.isInteger(+value)) {
                        callback(new Error("请输入数字值"));
                    } else {
                        if (phoneReg.test(value)) {
                            callback();
                        } else {
                            callback(new Error("电话号码格式不正确"));
                        }
                    }
                }, 100);
            };
            const checkPassword = (rule, value, callback) => {
                const passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
                if (!value) {
                    return callback(new Error("密码不能为空"));
                }
                if (value.length < 6 || value.length > 12) {
                    return callback(new Error("密码长度在6到12个字符"));
                }
                setTimeout(() => {
                    if (passwordReg.test(value)) {
                        callback();
                    } else {
                        callback(new Error("密码必须包含字母和数字的组合"));
                    }
                }, 100);
            };
            const checkRePassword = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("确认密码不能为空"));
                }
                if (value.length < 6 || value.length > 12) {
                    return callback(new Error("密码长度在6到12个字符"));
                }
                setTimeout(() => {
                    if (this.addForm.password == value) {
                        callback();
                    } else {
                        callback(new Error("两次密码输入不一致，请确认"));
                    }
                }, 100);
            };
            return {
                flag: false,
                btnDisabled: false,
                departments: [],
                loading: true,
                total: 0,
                addDialogVisible: false, //添加对话框,
                editDialogVisible: false, //修改对话框
                assignDialogVisible: false, //分配角色对话框
                optionsThree: regionData,
                organOptions: [],
                schoolOptions: [],
                userOptions: [
                    {id: 0, name: "系统用户"}
                ],
                selectedThree: [],
                selectedThreeAdd: [],
                labelPosition: "right", //lable对齐方式
                //查询对象
                queryMap: {
                    currentPage: 1,
                    pageSize: 10,
                    username: "",
                    type: ""
                },
                userList: [],
                dialogTitle: "",
                dialogType: "",
                addForm: {
                    type: "",
                    username: "",
                    nickname: "",
                    password: "",
                    phoneNumber: "",
                    email: "",
                    sex: ""
                }, //添加表单
                editForm: {}, //更新表单
                addFormRules: {
                    username: [
                        {required: true, message: "请输入登录名", trigger: "blur"},
                        {min: 3, max: 10, message: "长度在 3 到 10 个字符", trigger: "blur"}
                    ],
                    password: [
                        {required: true, message: "", validator: checkPassword, trigger: "blur"},
                        {min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur"}
                    ],
                    repassword: [
                        {required: true, message: "", validator: checkRePassword, trigger: "blur"}
                    ],
                    phoneNumber: [
                        {
                            required: true,
                            message: "",
                            validator: checkPhone,
                            trigger: "blur"
                        }
                    ],
                    nickname: [
                        {required: true, message: "请输入真实姓名", trigger: "blur"},
                        {min: 2, max: 6, message: "长度在 2 到 6 个字符", trigger: "blur"}
                    ],
                    sex: [
                        {required: true, message: "请选择性别", trigger: "blur"},
                    ]
                }, //添加表单验证规则
                roles: [], //角色
                value: [], //用户拥有的角色
                uid: "",
              imageUrl:'',
              infoDialogVisible: false
            };
        },
        methods: {

            /**
             * 重置密码
             */
            async resetPassword(id) {
                const res = await this.$confirm(
                    "此操作重置该用户密码为[ a123456 ], 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                ).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消重置密码"
                    });
                });
                if (res === "confirm") {
                    const {data: res} = await this.$http.put("system/user/reset/" + id, {password: "a123456"});
                    if (res.data.code == 200) {
                        this.$notify.success({
                            title: '操作成功',
                            message: '用户密码重置成功',
                        });
                        await this.getUserList();
                    } else {
                        this.$message.error(res.data.user);
                    }
                }
            },

            /**
             * 添加用户
             */
            addFunc() {
                this.dialogTitle = "添加用户";
                this.dialogType = "add";
                this.addDialogVisible = true;

                this.addForm = {
                  type: "",
                  username: "",
                  nickname: "",
                  password: "",
                  phoneNumber: "",
                  email: "",
                  sex: ""
                };
                this.selectedThreeAdd = [];
            },

            /**
             * 更新用户
             */
            editFunc(id) {
                this.dialogTitle = "编辑用户";
                this.dialogType = "edit";
                this.addDialogVisible = true;
                this.edit(id);
            },
          /**
           * 详细
           * @param id
           */
          info(id) {
            this.infoDialogVisible = true
            this.edit(id);
          },


            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    currentPage: 1,
                    pageSize: 10,
                    username: "",
                };
                this.selectedThree = [];
                this.organOptions = [];
                this.getUserList();
            },

            /**
             * 加载用户列表
             */
            async getUserList() {
                const {data: res} = await this.$http.get("system/findUserList", {
                    params: this.queryMap
                });
                this.total = res.data.users.total;
                this.userList = res.data.users.list;
                this.loading = false;
            },

            /**
             * 删除用户
             */
            async del(id) {
                const res = await this.$confirm(
                    "此操作将永久删除该用户, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                ).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
                if (res === "confirm") {
                    const {data: res} = await this.$http.delete("system/user/" + id);
                    if (res.data.code == 1) {
                        this.$notify.success({
                            title: '操作成功',
                            message: '用户删除成功',
                        });
                        await this.getUserList();
                    } else {
                        this.$message.error(res.data.errorMsg);
                    }
                }
            },

            /**
             * 弹出框确定
             */
            confirm() {
                if (this.dialogType == "add") {
                    this.addUser();
                } else {
                    this.updateUser();
                }
            },

            /**
             * 添加用户
             */
            async addUser() {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        this.btnDisabled = true;
                        const {data: res} = await this.$http.post("system/user", this.addForm);
                        this.btnDisabled = false;
                        if (res.data.code == 200) {
                            this.$notify.success({
                                title: '操作成功',
                                message: '用户添加成功',
                            });
                            this.addForm = {};
                            await this.getUserList();
                        } else {
                            return this.$message.error("用户添加失败:" + res.data.errorMsg);
                        }
                        this.addDialogVisible = false;
                    }
                });
            },

            /**
             * 更新用户
             */
            async updateUser() {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        this.btnDisabled = true;
                        if (this.addForm.type == 2) {
                            this.addForm.organId = null;
                        } else if (this.addForm.type == 3) {
                            this.addForm.schoolId = null;
                        }
                        // console.log("更新用户参数：" + JSON.stringify(this.addForm));
                        const {data: res} = await this.$http.put(
                            "system/user",
                            this.addForm
                        );
                        this.btnDisabled = false;
                        if (res.data.code == 200) {
                            this.$notify({
                                title: "操作成功",
                                message: "用户基本信息已更新",
                                type: "success"
                            });
                            this.addForm = {};
                            await this.getUserList();
                            // await this.getDepartmets();
                        } else {
                            this.$message.error("用户信息更新失败:" + res.data.errorMsg);
                        }
                        this.addDialogVisible = false;
                    }
                });
            },

            /**
             * 搜索用户
             */
            searchUser() {
                this.queryMap.currentPage = 1;
                this.getUserList();
            },

            /**
             * 修改用户信息
             */
            async edit(id) {
                const {data: res} = await this.$http.get("system/user/" + id);
                if (res.data.code == 200) {
                    this.addForm = res.data.user;
                } else {
                    return this.$message.error("用户信息编辑失败:" + res.data.errorMsg);
                }
            },

            /**
             *  改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;
                this.getUserList();
            },

            /**
             * 翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
                this.getUserList();
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.$refs.addFormRef.clearValidate();
                this.addForm.birth = "";
                this.addForm = {};
            },


            /**
             * 关闭编辑弹出框
             */
            editClose() {
                this.$refs.editFormRef.clearValidate();
                this.editForm = {};
            },
        },
        created() {
            this.getUserList();
        }
    };
</script>
